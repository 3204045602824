import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { graphql, Link } from "gatsby";
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../components/Grid';
import { MainProject, CardStaticImg, CardLink, CardContainer, CardBody } from '../../../components/Card';
import { ButtonGhost } from '../../../components/Button';
import { designTokens } from '../../../components/Theme/designTokens';
import projects from '../../../data/projects';
import { IconBox, IconCard } from '../../../components/IconBox';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Text';
import { CallToAction } from '../../../components/CallToAction';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdFlag from 'react-ionicons/lib/MdFlag';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="DisputeLab" mdxType="SEO" />
    <IntroProject projectName="DisputeLab" projectDescription="Payments and Large Data Management for Banks" projectCaption="Enabling financial enterprises to filter, optimize, and submit thousands of dispute resolutions" projectYear="2016 - 2019" projectImage="disputelab-preview" mdxType="IntroProject" />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Overview 📋</h5>
            <p>The use of credit card transactions are increasing exponentially in today's retail-centric markets. With that occurence, the banks of credit card acquirers have been flooded with credit card disputes - and are having trouble scaling their operation to keep up with resolving them.</p>
            <p>Handling the disputes sent from retailers could use a more efficient platform to help them process more disputes.</p>
            <p>As the only designer on a small engineering team, and with a blank slate, it was great being able to help create what the workflow for a bank working between it's clients (retailers) and credit card companies could look like in DisputeLab. Over several months, I, along with a small team of 5, worked to produce a fresh new take on enterprise banking software for disputes.</p>
            <IconCard cardTitle="My Role" cardText="Conduct user research, sketch, mockup static interfaces, front-end development, illustrate icons, outline usability testing, and design feature workflows." mdxType="IconCard">
              <MdPerson fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdPerson" />
            </IconCard>
            <IconCard cardTitle="Specfications" cardText="Optimizing the transaction dispute process through intelligent case building/sorting and bridging the inefficiencies between banks and credit card companies." mdxType="IconCard">
              <MdFlag fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdFlag" />
            </IconCard>
            <p>Our objective was to resolve current inefficiencies related to the backend processing of dispute cycle activity (dispute documents received by the acquiring bank).</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAClElEQVQ4y31US08TURjlp/gT3LlygQujJqILV5poNDEGN+xwIwlEE2M04MIFi0YT0hCIoEULRFCUUMaUllDBMi0WaCHTeTDQx7xn7vR4p9NOC6LfZHLvfI8z53vc24GG1Gq1Y+tJXXNvWzYsyzrV35OO9uCm0XVdqnBbevq4Dql/R/tCGO3s9W2o/RswAKKS3cljLsaAENICdf3ApXOPkTr/wtdT//8ybALOMysIR2b+Yq5oBsJ3hyBGEr6NnAIYpOmxoXtCU3s5PI5v8dXAiTR+lGJ5XOgewVqOD/THaknfjpOF3dkTcPZmL76mUng42I/I8kJgC7//jq5bT6CTZom8KrY1r52hGI/DzWUxx+bQNTKKL+w6zty/hOnVWADY8+opeoYHUWEYSAvzjTqSFmiToSfJzk7sP7iHDU4Es8UilVnHo9BzHKjlut2go3JxoBuvF6ewffUasjeu06KKsNOJOlDNT9UHdHQd/JXLsN5NIC2UIFDQJGU8MzUVpMPzPPpCQ8jsZHB4+w6MsTHU9rZBUsljzasDCiUFs59+oFwUUJJ2YSplzM5/xkoiHqQbiy3j2UA/8kURH2ZXIXMyDK4AWxDg+jm3xmaDLWI8wkI55FDZnIR2JIB5Mw0+m4esmfUAZplBNPoR7JaIicmfqFYNSAciFNXA0hqHfVltAe7lD1DIiCDVIiz5FyoFEfm3i1C5EhbXCuCKFWR2ZexLZfDcEQqUgGkaOJRElFUL0U0Rgmq25rCiqLAdBzZ1Io4BU1Zg5ySoion8bwkaDUoLVcrWhqZrsBwbqm7QvQ6DxnCKAsdtdNkD9MC8U+LQoSZ0+m065A49yw5dvcvAG+CqTXV1H9/Xi3G8k2Lr9MZQ4ZoWiKbiD8XQDMNk/DdtAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Credit Cards",
          "title": "Credit Cards",
          "src": "/static/ffbcbde36c617d0aab7a1e54ccdd6039/d9199/credit-cards.png",
          "srcSet": ["/static/ffbcbde36c617d0aab7a1e54ccdd6039/8ff5a/credit-cards.png 240w", "/static/ffbcbde36c617d0aab7a1e54ccdd6039/e85cb/credit-cards.png 480w", "/static/ffbcbde36c617d0aab7a1e54ccdd6039/d9199/credit-cards.png 960w", "/static/ffbcbde36c617d0aab7a1e54ccdd6039/67a79/credit-cards.png 1408w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <CSSGrid mdxType="CSSGrid">
  <Col mdxType="Col">
    <Box center bg={({
          theme
        }) => theme.greenTransparent} marginBottom={designTokens.space[6]} paddingY={designTokens.space[6]} mdxType="Box">
      <Container mdxType="Container">
        <CSSGrid mdxType="CSSGrid">
          <Col mdxType="Col">
            <Text center mdxType="Text">Problem:</Text>
            <p className="lead">Banks are having trouble managing credit card disputes coming from their clients, retailers and merchants - therefore being fined per case that is not resolved after 90 days</p>
          </Col>
        </CSSGrid>
      </Container>
    </Box>
  </Col>
    </CSSGrid>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Dashboard</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsSAAALEgHS3X78AAACXUlEQVQozz1SSU8bMRSe/1hCbz21Eouoeui9t1JaqFQhVaUHRFVBCRCGsCUKaoMCCoggxCIokJBkspJkJrPaHnv8PPUUxNMn+/n5+95iWRFamldSUNuB1j608tA+hPsj6BxD5wS6Z6Cfg3EJxjU3b7l1B3YFnBq4DeE1wa0rzl22Va8UCscv3kzFhscHht4PDI3Hhj/8x8TgiMSnwVGJydjIZGx0cmBk6vnY52cv301/m1Pcck63SPO+pya3VHVrLZlKJrdX1zYS6kZ8aXVhMTH/K/FzfmlhcSW+rMaX1OVEclXdjC8nD/azCq7mHMcGIcIwNAOTgm9xXx5kgHEZC0FIP7qNLHIeEAa4H4kt2xUh3Fy1Upl4+nRvPZs+uaik8plcvuC6jushy7JcuSHsYUx8n1Lq0wDbXQVpORmX6fs9s3FzVi5d1y4KpeJpqXjeKN1ajoMxRhKejzxCGJViQnyEqe92FdbMRz1wTqjPQDAOYTSD4CAo4xj7lu3ZyGPAAMBxcd/zMPUEcGR1HsUcgBDiISzzytakmabZ6/V03WBCXP4pznw9um5jyTROqjNTh3tNEeLHyoJzTilzUTQPQkh2JueSDQeEaB79nq/mzq23s38l88tOYyw2kXk9zt22FB9ElaO3FS4BFgDxA7myQMhXQSRAlPCDj43pmR/bVcnct3F7/VW4MkewqbB6jtlN36whQ7O7VdLXvF4F6RXcr2K96nbLjlH3r/J6YhZ3ythu0c4dvr/ymOHrRUVoKV7efEBQ3njyn8BK64HkdHeDSpprmaD2m9V2eS0rv/M/AzNrpNNxSyQAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dashboard",
          "title": "Dashboard",
          "src": "/static/d1614a3447c4a426ea73d70f0c0a106f/d9199/dl-dashboard.png",
          "srcSet": ["/static/d1614a3447c4a426ea73d70f0c0a106f/8ff5a/dl-dashboard.png 240w", "/static/d1614a3447c4a426ea73d70f0c0a106f/e85cb/dl-dashboard.png 480w", "/static/d1614a3447c4a426ea73d70f0c0a106f/d9199/dl-dashboard.png 960w", "/static/d1614a3447c4a426ea73d70f0c0a106f/07a9c/dl-dashboard.png 1440w", "/static/d1614a3447c4a426ea73d70f0c0a106f/0d390/dl-dashboard.png 1472w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
          <p>One of my major responsibilities whilst at Chargebacks911 was the designing the UI system and features for DisputeLab — a new dispute optimization and productivity tool aimed at large banks.</p>
          <p>DisputeLab's readily-integrated platform allows for the sorting, filtering, and optimizing of millions of transactions and disputes. By integrating directly with credit card schemes, users can eliminate the need for jumping to each credit card's proprietary applications to add dispute information.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>What is a dispute? ❓</h5>
            <p>A dispute is when a customer disagrees with a charge on a transaction. A merchant or retailer may use the term 'chargeback' while a bank or card scheme might use 'dispute'.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Payment Flow</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.166666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAACE4AAAhOAFFljFgAAABS0lEQVQoz42S3U7CQBCF+9BeGLESxTfgrYhcEC65MUqwrcWyrdvt/h5nCluRSGCTyTQ7u9+ezpwEhxVCGLIxBtZaWM7OISiFoDt4qtlYo/DexzshYpJTIB8SQiDLMxRFAdm28GKH8PYKWde0nyPPMnxsNlD00EUg55YgrWwhpUTXdQikElqjI0BVVSi3FYpyC0N7Z4ERFmtCCyinhpoLv+e+ju4cfvt/hcfAXBZQdg90rJDWfP6Cm9t7pI/PGI/HWC6XEXoe2B8IHutmDef3IB4Sr6fJBEmSDDGdTmP9ssLsO4fx5o/C2WyGu9EID6QwTVMsFovzCk9VSisHhfuiHz531/YwZrYDT5inrWmSlgToftC6txRHTRaKrbjow6ZpkJHXPsuSrNJCKI/32sNZ0++vVivk5Ec291U+ZCX8esyDdaiX7MsYsbfHwB+WIEv0PAChCwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Payment Flow",
          "title": "Payment Flow",
          "src": "/static/489fa39054dfb892d2b023029dc8153e/d9199/dl-flow.png",
          "srcSet": ["/static/489fa39054dfb892d2b023029dc8153e/8ff5a/dl-flow.png 240w", "/static/489fa39054dfb892d2b023029dc8153e/e85cb/dl-flow.png 480w", "/static/489fa39054dfb892d2b023029dc8153e/d9199/dl-flow.png 960w", "/static/489fa39054dfb892d2b023029dc8153e/07a9c/dl-flow.png 1440w", "/static/489fa39054dfb892d2b023029dc8153e/29114/dl-flow.png 1920w", "/static/489fa39054dfb892d2b023029dc8153e/97a96/dl-flow.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <p>When customers file a chargeback with their card issuer, this means that they are disputing a charge and request the card issuer for a refund. A chargeback is a transaction that a card issuer returns to an acquirer as a financial liability and which, in turn, an acquirer may return to the merchant. There are three common reasons for chargeback including fraud, processing errors, other disputes (e.g. customer disputes). The card schemes define the chargeback process, rules and regulations.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Discovery and Research 🕵️‍♀️</h5>
            <p>After doing a bit of process research with prospective clients, we uncovered multiple points of frustration that we believed could be rectified</p>
            <p>Based on those points, we defined a few high-level goals for DisputeLab:</p>
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text bold color={({
                    theme
                  }) => theme.green} mdxType="Text">Goal #1</Text>
                <Text mdxType="Text">Manage small teams</Text>
              </CardBody>
            </CardContainer>
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text bold color={({
                    theme
                  }) => theme.green} mdxType="Text">Goal #2</Text>
                <Text mdxType="Text">Filter cases using rules</Text>
              </CardBody>
            </CardContainer>
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text bold color={({
                    theme
                  }) => theme.green} mdxType="Text">Goal #3</Text>
                <Text mdxType="Text">Custom-tailor the antiquated process of handling the disputed transactions their clients receive</Text>
              </CardBody>
            </CardContainer>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Process Architecture</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsSAAALEgHS3X78AAABj0lEQVQoz22SaW/UQAyG9///MMQXipDoRWmXJk3Inbk8pz3BSXZhI2E50liZx/Zrz+nuTK8Nsr+16VdPq3exHKkYsByxnqiesJ5TI7AV2MnUqzQonDT2Ek/3ZVyWvKyWnffRWTQaADLhkinGsB6Ott82Nq1w3gyJhklobZh8PZ+jc1bIqmmNgXwFbmF9henmTyZOlNJClBfCGDnrfyurC0wUwUoNSikiwpzFOOlx6FRSwgWtZqmMMSld+g9hVapggwkxe2dAz1Ijd4/Et3+ci89fn5pBhRiatq/qpm0757x1vq4qMlpaPH0vAie7fxk+fZtcX3qAPb3zwVogTHxmBZ7LBf7iOIlhHHmKElZ47UGb0M/OyZlSOsjjETCab+e10BYKc9XMQUKM2nCVhOTXstY5x8XAWl7ApgYhWh4XbffnHaZt2vlvSmV+t53ShvXHEH1IzUct5pm2RXAfOzzpdPtIluM6/4U8Tn4wC6YMetdygb+8xccCHgpz8PdjWMJjYZ7Y3/XzB7xU8LOC58r9AVjesxTbsoisAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Process Flow",
          "title": "Process Flow",
          "src": "/static/5f86e586be9d2c2e4071280cb5986524/d9199/dl-process.png",
          "srcSet": ["/static/5f86e586be9d2c2e4071280cb5986524/8ff5a/dl-process.png 240w", "/static/5f86e586be9d2c2e4071280cb5986524/e85cb/dl-process.png 480w", "/static/5f86e586be9d2c2e4071280cb5986524/d9199/dl-process.png 960w", "/static/5f86e586be9d2c2e4071280cb5986524/07a9c/dl-process.png 1440w", "/static/5f86e586be9d2c2e4071280cb5986524/0d390/dl-process.png 1472w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Journey Mapping and Task Flows 🔀</h5>
            <p>In order to align teams and bring clarity to what was needed for this project, we went through several phases of mapping out the task flows and architecture of the case management experience.</p>
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <p style={{
                    marginBottom: '0'
                  }}><i>How can we reach acquiring banks that are having problems with credit card disputes?</i></p>
              </CardBody>
            </CardContainer>
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <p style={{
                    marginBottom: '0'
                  }}><i>What steps should we take to integrate a bank's data sources?</i></p>
              </CardBody>
            </CardContainer>
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <p style={{
                    marginBottom: '0'
                  }}><i>Which metrics can we track to visualize success on each facet of the project?</i></p>
              </CardBody>
            </CardContainer>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Journey Maps</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2yyBRxP/xAAZEAEAAwEBAAAAAAAAAAAAAAABEBESAAL/2gAIAQEAAQUCfJeR7JFR/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhABAQEAAAAAAAAAAAAAAAAAMQAg/9oACAEBAAY/AiM//8QAGRAAAwADAAAAAAAAAAAAAAAAAAEREDGh/9oACAEBAAE/IWKdMcF7EvSIRj//2gAMAwEAAgADAAAAEJT/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxBVMbC//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAECAQE/EDFnb//EABsQAAIDAAMAAAAAAAAAAAAAAAEhABExEFGB/9oACAEBAAE/ECtYRswcx6UH1SC9MpeDqYLM4//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Journey Map",
          "title": "Journey Map",
          "src": "/static/0d5311e0db0388f68930bed974ee1623/6a068/dl-map.jpg",
          "srcSet": ["/static/0d5311e0db0388f68930bed974ee1623/09b79/dl-map.jpg 240w", "/static/0d5311e0db0388f68930bed974ee1623/7cc5e/dl-map.jpg 480w", "/static/0d5311e0db0388f68930bed974ee1623/6a068/dl-map.jpg 960w", "/static/0d5311e0db0388f68930bed974ee1623/644c5/dl-map.jpg 1440w", "/static/0d5311e0db0388f68930bed974ee1623/0f98f/dl-map.jpg 1920w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60.83333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAACHUlEQVQoz4WT2W7UMBSG58V5CSQkXoHlApWLQstyhYoqioC2UtWNdJZM4mx2NsdZ5uNkhtKBgrB0ZCfK+f0vJ5NH777yUCowJdtrtRoYhmF9fvH5kgc7Hzn1U+gcYbhkGWn8uCRQCVVtf/asmBRGo40hyy1Z4SiqhpvZnLwoqKoKa2t0rIiygrbrySv5xnZM/VjAUoK0JpW+umnWoJMjL8QIWGl7ajcQyo27e/uEUYxSEU1dcDoL8UK5QIC++wbvJsLzNTeqYJYY5lGJL+CNXD55/P4YlVhct5FndMbLV3vMfZ8gCEVHz9PDMz6dB+iypSotyXxJmmjCOCMRdU07SHV0Xcfk2eEFSXYHqLVmd/8NcZqS5wVDa3n77YJZoEV+Qyz+WbFpcJa+bbB1/Zv3k64f1maONa5GvOj7fn2uxexOmg4vPJ4cnHC1COhticliIrEkSTWtLbeClFC2HzYg9bqcc8IwF8CWgzOPnS9XLPNNmnluRK4W1hkMjZCwtK3bMLxldws47uO43Nb4bIVpI4H0knKYlFIFQZwTZyWRrlkos56CpTZ3DLdZbq9B3n24DJhKoomxXC8yZtOYhSQ7VSUzlcvoWMKs5jxQ9yX/ybiX/fXJlDNPSVAyrxJWESUy3w4nyWphNe79sKIV7yf8Y/0CFNnPj644uVaY0qEzTZUm9K75a99/AUfJnvhV2hYtf8T5NEFFisa5e4rG+gFb6pe3fWi8CAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Task Map",
          "title": "Task Map",
          "src": "/static/6392c96dffbe9d0cc20318af31f4e837/d9199/dl-map-2.png",
          "srcSet": ["/static/6392c96dffbe9d0cc20318af31f4e837/8ff5a/dl-map-2.png 240w", "/static/6392c96dffbe9d0cc20318af31f4e837/e85cb/dl-map-2.png 480w", "/static/6392c96dffbe9d0cc20318af31f4e837/d9199/dl-map-2.png 960w", "/static/6392c96dffbe9d0cc20318af31f4e837/07a9c/dl-map-2.png 1440w", "/static/6392c96dffbe9d0cc20318af31f4e837/29114/dl-map-2.png 1920w", "/static/6392c96dffbe9d0cc20318af31f4e837/a5262/dl-map-2.png 2388w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Design Process 👩‍💻</h5>
            <p>I thought it would be easy to not only keep track of in progress designs through a simple list in which developers, PM's, and support teams could easily sift through, but also be a way great way to test a few expiremental UI components.</p>
            <p>While we've used tools such as InVision, Axure, and simple PDF's before for step-by-step UI walkthroughs, I found as though I receieved feedback quicker and more efficiently by using Google Slides - so I made sure each list item linked to the respective feature document.</p>
            <p>The simple list was built using simple BEM-flavored HTML, SCSS, modern JS, and the design tokens we've used across DisputeLab - along with a few experimental features (CSS Variables, dark mode toggles, etc.).</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Simple List documenting the respective feature design documentation</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUklEQVQoz32R2VLCYAyFeQ0tdP+7b3ShKy2iMzDqna/hjG/mKx7TIIIdykUmneT0+0+ShaKamIasGJx1w4aqCdzTTGNxS8RZ9xAkFSwnwqOkQFqqWMk69/7ppsCz4Lo5/mg7Iepmh6reotg02JQtLNuHplvs+r7D37HOohHouAFBGoZFcYYgTKgW0kMBQ2eBpnCR5RUccvQHVHQI4cH1IhimQwCbIbrhcFw7nIIXwgpQNs9w/ZSdqVR8oHyMEry1A9KyI4c54qTgnKxLCMtj7U2gV33g9esb2eETq6XM4y9JnNDIHcGqcY9Vz/usmwFNu4Mp7gA1M4SIeuj2mpoXh3s/xrEe0O8PaAiWZhW7i+IC45pmgTI1RmeyrP0VpZWOTRriqe9PDuueDrRlaBjlvNfZHZ4+Lldmh5KG41Dg/fCCtOiQF+O1W4J2KKst7dCfdfgDXDIj5CcJyf4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Design Documents",
          "title": "Design Documents",
          "src": "/static/3b568c80086e62650c0be9447b185cc5/d9199/dl-docs.png",
          "srcSet": ["/static/3b568c80086e62650c0be9447b185cc5/8ff5a/dl-docs.png 240w", "/static/3b568c80086e62650c0be9447b185cc5/e85cb/dl-docs.png 480w", "/static/3b568c80086e62650c0be9447b185cc5/d9199/dl-docs.png 960w", "/static/3b568c80086e62650c0be9447b185cc5/07a9c/dl-docs.png 1440w", "/static/3b568c80086e62650c0be9447b185cc5/29114/dl-docs.png 1920w", "/static/3b568c80086e62650c0be9447b185cc5/5f7f2/dl-docs.png 3356w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Design System 🎨</h5>
            <p>One of the pain points I came across designing the applications for merchants was the inconsistency in experience and unorganized styles for components. We thought if the DisputeLab team had access to a source of truth for the design of the UI, we could aim for consistency and can embed UX principles directly in our component design. Finding time to document our UI while still designing features was another story - it was simply going to take a little extra effort. I rolled up my sleeves and began building a unified style guide for our product.</p>
            <p>It was clear that as the devlopment team and volume of work was growing, the inconsistencies across the product were as well. Not only that, but newer parts of the product clashed with the legacy features, making the ecosystem kind of jarring for the end-user. If I could build a style guide and a source of truth with our UI components, we could help stop the use of bespoke code while still increasing the speed at which we can develop.</p>
            <p><strong>So we created Prelude Design System as a style guide for DisputeLab's UI.</strong></p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Logo for the design system</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "39.583333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAACE4AAAhOAFFljFgAAABP0lEQVQoz5WRS0sCYRSG/Ssto0WLatMPCNq37Ee06Q9Ei1oF7bpAi0pMQUnJkhCT8lKZliY6RkQkKRZKNuoMM87T6IyW2oVevsU5L5znXD4LHWmtp7XDfEFCUw1bVVX+kqarE1pMg6bpvcgNprfCLCTL7dzt9uBy7SMIOUKhMInENZlMFp/vmGQq1QWazEFgTnxjaM3LbOSZhm4FAyf4/QEOvIfs2R3YbHbWNzaxWm1s7+z2D2kAO9DOyqeJCoV3tWX2rKbouaI2kWUZRVGMGhM0sLJmHLEL/ad6gV3oZ0cCNyWOokUy1QrLQpJ8rY7jqcFdTcPr8eDU7/ql7heg2XBmMcLUXIj5qygW6yoraYGJoMjSbZnJsXGGR0Z//uV+aEvphzLx7CvFuojz8Z6qJHFWEClJTeKxGOcXl99O+AH8AlUthwGvegAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Prelude Logo",
          "title": "Prelude Logo",
          "src": "/static/871773df540ad9369ebb87e2712ded8c/d9199/dl-prelude-logo.png",
          "srcSet": ["/static/871773df540ad9369ebb87e2712ded8c/8ff5a/dl-prelude-logo.png 240w", "/static/871773df540ad9369ebb87e2712ded8c/e85cb/dl-prelude-logo.png 480w", "/static/871773df540ad9369ebb87e2712ded8c/d9199/dl-prelude-logo.png 960w", "/static/871773df540ad9369ebb87e2712ded8c/07a9c/dl-prelude-logo.png 1440w", "/static/871773df540ad9369ebb87e2712ded8c/29114/dl-prelude-logo.png 1920w", "/static/871773df540ad9369ebb87e2712ded8c/97a96/dl-prelude-logo.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Design system documentation</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsSAAALEgHS3X78AAACZUlEQVQoz0VRS08UQRDem/HozRiPxosn/4I3LyZKPGyMiRAPe1VPwEU9aDx48aB4MFETJTuBlccKRCCgsLuAPINCABd39sGyr56Z7p6efkxPW0OidiqV6qr6vqr+OiHs2fbvwvFePjOSTQ+PWxnw2XRmMv1pyhr5Yo1MW6Mz1ticNTafHv869HlxeCI3PFkYzOZX17cS1F6sIb5zUE6mHiZTj5OpRze6+7p6+sFfv9P737p7u3rAoNR7827f1eSDFy/fJHhl0fVIwIVPPOwi4jkUOz52lPBNJI0WWvFIcSWDJx9/FKvIGBFKpkMZeMcx2PMwF5JQ1mw79abTQa4LfNT3fQamdWSMqbfFpdS3bKE+5ZlcW0EmIO0Esxco9bXWe6Xmxk650WgihDzPA7zjOMCrQg2t77ZLF3pm+1/v39pUzw44ZBhpJYLyAnQAePMXWt1HUkqIzd8TmXhsAbXPTw+dGcicHRi9OLd9bYVtYKNpKyFreaiHOuLMp9jFmGCMYRfOuVJhFGPNYLV0auLD6ffW6bfpczOFKzk82YoUbcZgKMNugAcvpRICxqsQ7vEx6uTNz7d2L9+fv/dq9/aGeLofrx0LJqt56ICGSrGImogrDTBg8QPhEU6YAC05cAm9XUSNDm1i7gu4G9+p/ZscHttF1/GoH8DCMBzkbnU6mDBQnTEGu8AvgICUAh/nUvuoCuACiALaV37uOUcdwkMhQyBGhB7WK4TpQIRKaa6McMrMbbh+CERSGwaTlT3Dj9ZYbY0e5mgpj+0lAlZexvYyLi3Rk+AksxIHle+kukaq66S2GdRW/wAWHGYTjXy2CwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Prelude Documentation",
          "title": "Prelude Documentation",
          "src": "/static/0d1fe232fccb6f862af9481cdcddb281/d9199/dl-prelude-1.png",
          "srcSet": ["/static/0d1fe232fccb6f862af9481cdcddb281/8ff5a/dl-prelude-1.png 240w", "/static/0d1fe232fccb6f862af9481cdcddb281/e85cb/dl-prelude-1.png 480w", "/static/0d1fe232fccb6f862af9481cdcddb281/d9199/dl-prelude-1.png 960w", "/static/0d1fe232fccb6f862af9481cdcddb281/07a9c/dl-prelude-1.png 1440w", "/static/0d1fe232fccb6f862af9481cdcddb281/0d390/dl-prelude-1.png 1472w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <p>Inside the documentation, we not only laid out the foundation for how our components should look and operate, but also how our CSS and JS should be written - thereby creating a standardization for a growing team. We decided we would utilize BEM and OOCSS for our stylesheets and abide by AirBnb's rules for writing our JS.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACuElEQVQozz2S2W8bZRTF/XcjEQmcGKQCRW2hboEHCOUNice2tLyghhbHLrhJ43i8jO3Z9311/ONOKjHW8Z1vdL5zl3N7pTXl2e8vuXPvB+4//pm7D0/5eviErx7+wp0Hp3x275SfnvyKPR7hKmusyznOxQx3ucHf6vg7g0C38A2HwHToFb7KWnN5dfY355N/GY3/4c1owvjtFGWto6gm6mJBcP2etDmgmwG+LUhK/LQiyAQSvVTOUSaCrkIkB103MAwDvYO827ZDklfE5Z7Y9whnF2R5ieOGxGlOUZRUVUXbtnK/wIty/CCh10Yq9Z7/n4P8buPhcJvRiwupyCSaTUkksWbHhGFMkmTkWUaR50RJJ5jh+yJ4k+mUN9DsD3S6rQjdiGYrf11bXtrcCsbzC4r2BrcT66prG4kVRd1IF7Xwqg8tV9qEzNlRBTp1aFAJmsiiFsSOTuRZJNYKb/SKaL4kmK9xZiqlpqFLdFc7AlXDlXl7a41eMBnz9LdnHH10l8HxtwxOhgwGQ04Gjxh8/h19wffiuD8a8/r5n/Q/vs/x0QM+OfqGk0+HHPeH9PuP6J885osvfxRBRbJnBTOJy82OpbpF3enoloOyMbjeWiyvFviXV4RiyvVixWKtom627ISnmTarnclcuAu1q/BqJvPYo9sJUZyRxDH7fXtrTJLXJOJyaLkE03fUTUtVljRNI2YUNFUt54q8lDkKNxa3e4myoBSi7wWkaUYmznWkbiX8MMUTB+2NTvL+ikK+n529YTq94K3s6fn5hJcv/sB2ZS/jHM+PZQ+3WxqpJpWMiaxALkKd2x2CrCaQCh1pKZVOckn89PkL5suVdNWSFh/uRFKd30FEe8F4Qqhu0N5dYsic7Nk17lzBl0uWuGouVczpJf5fr4llbpYI68LtOLHc62DL/E2l46/4D7s2Z1cNzj2OAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Prelude Documentation",
          "title": "Prelude Documentation",
          "src": "/static/4d251596f6f9a81581ebc5f15477e21b/d9199/dl-prelude-2.png",
          "srcSet": ["/static/4d251596f6f9a81581ebc5f15477e21b/8ff5a/dl-prelude-2.png 240w", "/static/4d251596f6f9a81581ebc5f15477e21b/e85cb/dl-prelude-2.png 480w", "/static/4d251596f6f9a81581ebc5f15477e21b/d9199/dl-prelude-2.png 960w", "/static/4d251596f6f9a81581ebc5f15477e21b/07a9c/dl-prelude-2.png 1440w", "/static/4d251596f6f9a81581ebc5f15477e21b/29114/dl-prelude-2.png 1920w", "/static/4d251596f6f9a81581ebc5f15477e21b/91945/dl-prelude-2.png 2944w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Reconfiguring the navigation 🧭</h5>
            <p>As more and more features are added by stakeholders, we needed a way to organize the navigation items in the sidebar navigation. We didn't want to hide prominent items offscreen, so we came up with a solution to have drawers for our navigation items in the sidebar.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52.083333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAABpUlEQVQozy2Sia6sIAyG5/2f7iSzqKijggqyCSK4O7fOnaRpmoaPv9sNIZKmdZY3RUkx5nUtKFVCmHeO//4ej2eSoFzroe/d1yAYjBm+gbu9YhxFVZo1KCVZXleYlxUF/l3U93v0fuMsK/8/VcpYM4IHM2a84GeM73FZYfpMSJyWpO4eCS6qpqEavWsudFXLomqFNEnetJQTqpIcQ/6CI0SoGAYXUEGlDVyashbOzw1VLbfWedxKabyxI6Z6GCfGNZN2GAP0cksQHke3LCtpuBu9VIZSvu07YzJ4D3ku5L5v3oe+74/j0LqfpgD5C44RNsbO89q2IgSvtG0BmxfaQTgC01Lhpxm0pNL7cYCH7LJuP9jaAWBM2Lpt0AnrxHEpK+8nMNZp8L1xXKht26XUzrmfcoKIHZwPMyb0OPYLZvI8z6bhIYRpWhgT53kMbgTxfd+hnjAt6/qFo4SgrI4SXFYt9AZw18nP50Ng0t+RYky/s7BKQbez7u0Idfvwg18xeUYYlgzTqhuRpKSquhiRx6uMYwxfwxUUJauuE+KUaVibUlb37h9ITidQlrBhRQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Sidebar Navigation",
          "title": "Sidebar Navigation",
          "src": "/static/b00b71b564928be2a0b9e56584f2afd3/d9199/dl-sidebar.png",
          "srcSet": ["/static/b00b71b564928be2a0b9e56584f2afd3/8ff5a/dl-sidebar.png 240w", "/static/b00b71b564928be2a0b9e56584f2afd3/e85cb/dl-sidebar.png 480w", "/static/b00b71b564928be2a0b9e56584f2afd3/d9199/dl-sidebar.png 960w", "/static/b00b71b564928be2a0b9e56584f2afd3/07a9c/dl-sidebar.png 1440w", "/static/b00b71b564928be2a0b9e56584f2afd3/0d390/dl-sidebar.png 1472w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Merchant Admin 👩‍💼</h5>
            <p>Our users needed a way to manage their clients, in this case an acquiring bank's clients are retailers and merchants. With the Merchant Admin page, clients can:</p>
            <Box mdxType="Box">
              <ul>
                <li>Select communication methods</li>
                <li>View communication history</li>
                <li>View merchant details</li>
                <li>Create notes that would tag all disputes from that merchant</li>
              </ul>
            </Box>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.416666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAABgElEQVQoz31RTU/CQBDtP8KPu/4CQjRe/QuejBcg8W948G48edTEGLWAIKDxhCG2UAGJYD+23e5u96N12pKAJvoymUwy897bmdX61mz6Od8/PNncPd4oVdeK5fVStVCsFIrlv6OytXe0vXOgOV5AmXh5aneaeqtxX9Pvbm+u2w96p9XoNGvdVv35sdFp1btZzop6o66fnl2dX1xqCCEppVKxihMAJJFXGYRKApb8QhwnMxRPv7DmB4GQEmOCMQYJQhkmNErBlVLQopFUnPu9XvjaZ29GNBjywTAZms54rHk+TicoI4SAKpA9hFS8RGolBTFNahjUMIU1kqORsix78qGprE0yNyhYxEEFPOOfgK4QgkVpwJow6XhIy9cAQk6mLJrN5rBCttvCmTFmWe8MDislrMOFSMnukgzOLJuLXNellK5eCB7COV85WKroev6SnLuBiON6OCRcyIiLRQjJpYKcBpeMS/iRue0uyGAVBAF8lx8yGxHHJzYK/wkYmHza32cKFy3fHdVxAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Merchant Admin",
          "title": "Merchant Admin",
          "src": "/static/3cebb99219a0362ddc348f1cca045e4d/d9199/dl-merchant.png",
          "srcSet": ["/static/3cebb99219a0362ddc348f1cca045e4d/8ff5a/dl-merchant.png 240w", "/static/3cebb99219a0362ddc348f1cca045e4d/e85cb/dl-merchant.png 480w", "/static/3cebb99219a0362ddc348f1cca045e4d/d9199/dl-merchant.png 960w", "/static/3cebb99219a0362ddc348f1cca045e4d/07a9c/dl-merchant.png 1440w", "/static/3cebb99219a0362ddc348f1cca045e4d/29007/dl-merchant.png 1600w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Understanding the Dispute Process 🕵️‍♂️</h5>
            <p>The operational strains and inefficiencies stems from the way financial institutions have been going about this process. Manually compiling PDF's, or worse 😩, printing the different case documents out and scanning a compiled version. The basics of it are that in order to dispute a case, you need all the required documents from each step of the dispute process:</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.66666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABpklEQVQ4y5VT23LUMAzN//8QfEeB8lJgaHcTJ7ZjW7aTpdPOQZKTZdkuDDwo1pGOdbPShVBAtCKlBaJf4nnOiLEybr5feNVzxztXOF0IhL4fMAwGpSzsLIqtdTidnuF9VN+6nlh+MA44HnsOlBg/Y5ocjBnx8vKKnBd0EsB7p5JzYalwznHGGbVKgqyYKG2YOJnlgJHxylVF9dfa7nZElZXM1RW9IMa3mBRLB4KJiH11w+WMtUL5SPQmi5LE+Tsum23ZpGBZqlbYAhduf2kBYyxcPnGWooYdt8raTG9hSSTdxZg3XNXXyes4RzxYz2dS3drID2PVNhge+ujPWER04Qjfe+KHCSrzrK8sq5KVcCk7yQwekwnoj7bpm/2aLzF0bfb9eSu8Z0zqw4h+NpgSbwKvSmT7bX7b025XriVw+X5OeJi/4KP9hKd8QJ8GtYnv5p1wbvkqU9hIfH6N3/Du4T3u3WeYNHJAOldz6+4fA2qF/BdJZXf2A77TI0yYdPB7wn8PuJGdTziEXlt9jE8YnNUZXrb4XwHlHGVlzITDyP+3C39tV+QnCPEpRWaEwYUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dispute Case Process",
          "title": "Dispute Case Process",
          "src": "/static/c6476b09ce302353dc0965f916e6a2aa/d9199/dl-cases.png",
          "srcSet": ["/static/c6476b09ce302353dc0965f916e6a2aa/8ff5a/dl-cases.png 240w", "/static/c6476b09ce302353dc0965f916e6a2aa/e85cb/dl-cases.png 480w", "/static/c6476b09ce302353dc0965f916e6a2aa/d9199/dl-cases.png 960w", "/static/c6476b09ce302353dc0965f916e6a2aa/07a9c/dl-cases.png 1440w", "/static/c6476b09ce302353dc0965f916e6a2aa/29114/dl-cases.png 1920w", "/static/c6476b09ce302353dc0965f916e6a2aa/ac25d/dl-cases.png 2342w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <p>After you've compiled the cases, you would send to the bank that issued the card to the consumer to see if it passes.</p>
            <p>This process is incredibly manual and tedious, so we rethought how users can compile cases by using <strong>digital data</strong> instead of a printed document.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Finalize dispute cases and send 📭</h5>
            <p>Users can compile documents for their clients' dispute cases, edit individual pdf pages, redact information, add notes, review information, route cases to a queue, and send to the credit card company directly through DisputeLab.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsSAAALEgHS3X78AAACJElEQVQoz1WQy27TQBiF/U4UJCQknoFNIwFblgjxCGUBYsGKJW+BEJfUbUrIjSS0pLRAm5KkDYnn6rn4Mo4z9vAndNH+Ojoaj3x8Pv9ec1T6Zw5UG7m9sdubuPrENc5d88K1pq7z13Vnrjdz/bk7CNwAuUGwOhwi9ws5721rfOfh89sPXmxsbt3Y3NqorP26boIqz+Bwq7J199Hre4/f3H/yqvL0pXd4hpp71YNuq9f+0u802s36+w/vfP9TbWd7F7Trg9d2/R2/ul39+Lnmtxr144Pe78NvR0ffvTGKnSvL0q1mmdmiFDrK8mWUmCTNzNLqKMltKVS0sDazpVnY9auuKErvz1xpJYzJrLUmVVGczIOAUIopwQjxUARBEAoZzAPKeLbIy7JY5guuks6J8sY41koKKY0xCQvjOJ1xhqUIKMdMYC4R5STUAWHgi3xVW65nkRfelBklwjQ10JxCb2rmlHCtMOOUi1BqQpnUMcIEyPPlJTMM/OAqrCUUJnkOSCWAIYSEEJQzSoiUCuBVFMFlKES+LK6FL6hJAFxpCGitU5PNGMGCI8oICwkXmDAaKkSAR15iX20WnMFiKGURYGfZnFGmJGYhYHOpMeWhihB8SV5iXwnTVdikBtYIqzCAjZEUgrA1tlpjK40xFits+39fYNYWXuss6w11fxTvn5v9iemO4vaJ7JyqzlC1T8TXoYZHcLjpDtXgIv0xNaDjqfk5y/4Bv9Z2j1hBoyEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Work Cases",
          "title": "Work Cases",
          "src": "/static/063c6b80ca190507707ad04f84882ddb/d9199/dl-work.png",
          "srcSet": ["/static/063c6b80ca190507707ad04f84882ddb/8ff5a/dl-work.png 240w", "/static/063c6b80ca190507707ad04f84882ddb/e85cb/dl-work.png 480w", "/static/063c6b80ca190507707ad04f84882ddb/d9199/dl-work.png 960w", "/static/063c6b80ca190507707ad04f84882ddb/07a9c/dl-work.png 1440w", "/static/063c6b80ca190507707ad04f84882ddb/0d390/dl-work.png 1472w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Pause Case Workflow</h5>
            <p>Since credit card companies have deadlines on dispute cases, banks felt the need to prioritize the workload of dispute cases efficiently. We created the Pend feature to allow users to pause cases and remove it from their personal bucket of working cases. When pausing a case, they can choose when it would pop back into their bucket of working cases, and even add it back into the bucket of working cases for a team or group.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "84.16666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVQ4y5VU2W7bMBDM//9Ff6JAP6AH8tAibZMWPWLHdmQ5kiiKFClSB6e7tK3Kag53gcESxGq4s0PqopAGhawhKoZBSRD/j7DnMOHisICUFbIsi5ljGMJZCCHAt13IS70njCcQqTaOurMQokDTNLFwDkyBwx5FOyVkySxXaodUOOx2OxSFwEsRDuDwp4Q1SmWQ5hofPq9x+TWhvMH7q/WIt5/u4t6XXzlubgWuCTeLMuZrymlhAjdFzU06VA2WicRiI7C4LwkCv1c5lrS+XRcxr7aSakrcUd06rcZM35IpemZKpVBrBVMr6KpEGFqcG+1cMkNrQy7nJL2iEbiI2noyyEVY2xAsOt+i7wdCHzEMA5zzM0LqUCnaECXNKKNZPVDOkZUWrrGojaEDNSnQGJwbXedrw9b4tj0lZMkVESbJFtvkPt7HrKhgXY+6oS7CzN3pVZpLZjImrY2FIUQ5JKPtBr4OEV3XjxLnYMnGNv92qDQ5LSW897HoWHxcP0fo/BOSWSrP8rFX8hj2BwY0U1OOkmnz5A0/STKZ3TH6vg3ZnND5PeFLXfE75jkbcv7bssSbyy1+rmSQ6vBSjoS8wcbo+nlwTUW16UOGd1cbvHr9Ax+/p6HSkefvDHkGmVBng79TRGLpnhLZ+D/8AxLrHvNGHa6ZAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Pend Cases",
          "title": "Pend Cases",
          "src": "/static/1466651125633b11cb0ba718fbe0e739/d9199/dl-pend.png",
          "srcSet": ["/static/1466651125633b11cb0ba718fbe0e739/8ff5a/dl-pend.png 240w", "/static/1466651125633b11cb0ba718fbe0e739/e85cb/dl-pend.png 480w", "/static/1466651125633b11cb0ba718fbe0e739/d9199/dl-pend.png 960w", "/static/1466651125633b11cb0ba718fbe0e739/07a9c/dl-pend.png 1440w", "/static/1466651125633b11cb0ba718fbe0e739/0e0c4/dl-pend.png 1884w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Custom Reports 📈</h5>
            <p>Admins users needed a way to view the tracking of teams, dispute resolutions, and periodic metrics. We needed a way for an admin user to:</p>
            <Box marginBottom={designTokens.space[4]} mdxType="Box">
              <ul>
                <li>Build custom queries within an interface</li>
                <li>Schedule report generation</li>
                <li>Query custom fields across dispute or user data sets</li>
                <li>Allow for mulptiple export formats</li>
              </ul>
            </Box>
            <p>We came up with a simple table view for users to view and toggle all of their currently-scheduled reports, as well as an interface to create custom-queried reports for user or dispute metrics.</p>
            <p>Users can schedule the generation of reports for custom lengths of time and schedule them to be generated periodically.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Building Rules for easier workflows 📥</h5>
            <p>One of the main pain points our clients were having with their legacy processes was the tedious workflow caused by non-automated workflows. With a numerous amount of credit card disputes coming from the exponential growth in credit casrd transactions, dispute teams at banks needed a way to route, filter, and take action on large batches of cases coming through their system.</p>
            <p>The aim is to offer a “low code” experience for the users to generate automated workflows using defined criteria with dynamic values. i.e., even a non-technical personnel or a business user could pick, define rules based on different types of criteria (e.g., currency, transaction value, region, etc.) and create an automated workflow.</p>
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text bold color={({
                    theme
                  }) => theme.pink} mdxType="Text">Problem</Text>
                <Text mdxType="Text">Users waste time (and money) on repetitive actions on new disputes</Text>
              </CardBody>
            </CardContainer>
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text bold color={({
                    theme
                  }) => theme.green} mdxType="Text">Solution</Text>
                <Text mdxType="Text">Users can leverage rules to build sequential logic targeting the large numbers of incoming disputes</Text>
              </CardBody>
            </CardContainer>
            <p>Using DisputeLab's rule engine, users are able to create modular rules targeting specific criteria, and subsequently take various types of actions (eg. assigning, declining, routing, pausing) on these disputes.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.41666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsSAAALEgHS3X78AAAB1klEQVQoz41SSW7VQBD1PRgiuAA3YJEA4irsOABBWcTmDyDCHbgCJ2CBkECswpbVhz/Y7qG6qwe3bV7biYQCC0qlUlW7n+vVqy7ef/hy78nZ0eOzOyent+HH2ZHfPXk55zf81vHp0aMXD54+v//wWfHx09eLi3fr9euyLFfLxds3S8SyrKqyWi6Wi+w3raqq8/NXq9W6IDLjOA7DgKjduKUxpPE/rWik0kRtK1KMm9p+/n748VNq0odaWHbehxi7ya8shDAnzodCKOOcIyIcgkDqBxeSc9aolmaXDaIhbYxh5q7LP0LAPwohFfugyPYpjcMYYjK+d3JjN9/c/tLtLv0+ex9pYjoA40Jnfc++K9DdsBVC4hQfY9db3wmltrtDK7VQtNvXLWrDh0bWQjdC/9o320bX0hQhdpZZSplSmsHGYbAopDSWwUsqpTVZmxuorI/RWgnlDtL9G4yhMCG0QPQQLSA4DIwUh8E74thqn8Gg/XdnCS2YtdboyZNBVKS5tEbosBPXnYUQXXcN5rwJpTJYZc6gSpzZXfEn0pJ8rabOnFdlJvCQUg8xIR7uMecVgic4w9AS7DN5x8rEBmC8gtT3f74blN7H6WqYAbD5YWDJc5lnJv8bSGKuP+Ayt7IAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Rules",
          "title": "Rules",
          "src": "/static/5b44030721bf1f9fdb6e28251272c55f/d9199/dl-rules.png",
          "srcSet": ["/static/5b44030721bf1f9fdb6e28251272c55f/8ff5a/dl-rules.png 240w", "/static/5b44030721bf1f9fdb6e28251272c55f/e85cb/dl-rules.png 480w", "/static/5b44030721bf1f9fdb6e28251272c55f/d9199/dl-rules.png 960w", "/static/5b44030721bf1f9fdb6e28251272c55f/07a9c/dl-rules.png 1440w", "/static/5b44030721bf1f9fdb6e28251272c55f/edd6a/dl-rules.png 1479w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
          <CardContainer mdxType="CardContainer">
            <CardBody mdxType="CardBody">
              <Text bold color={({
                    theme
                  }) => theme.green} mdxType="Text">Step 1: Connect to dispute ingestion</Text>
              <Text mdxType="Text">Internal connection from client to DisputeLab</Text>
            </CardBody>
          </CardContainer>
          <CardContainer mdxType="CardContainer">
            <CardBody mdxType="CardBody">
              <Text bold color={({
                    theme
                  }) => theme.green} mdxType="Text">Step 2: Define Rules</Text>
              <Text mdxType="Text">The rules are generally business logic conditions, such as <code>if this, then that</code>.</Text>
            </CardBody>
          </CardContainer>
          <CardContainer mdxType="CardContainer">
            <CardBody mdxType="CardBody">
              <Text bold color={({
                    theme
                  }) => theme.green} mdxType="Text">Step 3: Output</Text>
              <Text mdxType="Text">Users can quickly view the criteria and actions executed, the impact of the rule and whether the rule is active or inactive</Text>
            </CardBody>
          </CardContainer>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <p>We also separated the rule engine into two parts:</p>
            <CSSGrid mdxType="CSSGrid">
              <Col col={1 / 2} media={{
                  phone: 1
                }} mdxType="Col">
                <Box center bg={({
                    theme
                  }) => theme.grey900} color={({
                    theme
                  }) => theme.grey0} marginBottom={designTokens.space[4]} mdxType="Box">
                  Single, interval rules
                </Box>
              </Col>
              <Col col={1 / 2} media={{
                  phone: 1
                }} mdxType="Col">
                <Box center bg={({
                    theme
                  }) => theme.grey900} color={({
                    theme
                  }) => theme.grey0} marginBottom={designTokens.space[4]} mdxType="Box">
                  Sequential rule groups
                </Box>
              </Col>
            </CSSGrid>
            <p>Rule groups allowed users to build rules in grouped sequences; part of an encapsulated if/else scenario.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsSAAALEgHS3X78AAACFElEQVQoz22RuW8TQRTG96+g4iyoKJAQDQqIggopDX0kZEqKFFBSR0KRCKcCBSWi5rAcWbbji0RGggI7QUYhjuWATXZnj5nduWdneT4ioohPP61mZt8338wbpz1I338hS+/c5Y/uct59VEArBfS4gJ6soadF9KyInhfRi5K/WkIvS/6rClotea/Lwzflfv7zyPnpZQ9W3p64vHBx/t6Fm4un53JnrubOXrsDXxgf5dRc7uSV2+dv3L00v3ju+q2F+w+dzkBtd7Y26pXOZmOrWV0vF9cK+UL+Q7lUrNeq9WqlXgPWgWaj+qlR26xXWhuNVqv5vfvD6fR5GIQeCnwPaam0zYSxOBExk1waKgANJEJra02WxQmjjGdZxqR12n0eRTgkDBEhlFEaCsbSaRYl6sB1CRUStgTZLDVpklBCiDGaUDVOjkiC4nTvQESJnpqh1qRZQPhwOCIJT21mJ3aT2ghjhAIlZcz0JBnHARE+UUz8SwZBopSSC2WnVtjUWhehKIqMVhiSt/clFP0JxQAJTDWUwuEBLhXcLWGcMgHjw0UdRDG0gKsUkbFZcWV+BbLvcpgHMXMx9SagQ6ZTWPcJoDBLg8SMQjk2a2gDN2GihU7DmB21HcMnDB7CC2KlJBxzbJ40aCZMmR/T4BhkRhhTa1MQWDA1ztee6g5od39Gu4e/7f6PHoZfnT2885vujlhvxHaG4i+d2269FUqs5QAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Custom Reports",
          "title": "Custom Reports",
          "src": "/static/568588644e9f318df85d8fb4e8750757/d9199/dl-reports.png",
          "srcSet": ["/static/568588644e9f318df85d8fb4e8750757/8ff5a/dl-reports.png 240w", "/static/568588644e9f318df85d8fb4e8750757/e85cb/dl-reports.png 480w", "/static/568588644e9f318df85d8fb4e8750757/d9199/dl-reports.png 960w", "/static/568588644e9f318df85d8fb4e8750757/07a9c/dl-reports.png 1440w", "/static/568588644e9f318df85d8fb4e8750757/0d390/dl-reports.png 1472w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Email Notifications 📨</h5>
            <p>We used MJML, a React-based email syntax for building bulletproof emails, to help us put together our email notifications for:</p>
            <Box mdxType="Box">
              <ul>
                <li>Password changes</li>
                <li>Account creations</li>
                <li>Letter emails</li>
                <li>Letter emails</li>
                <li>Account changes</li>
              </ul>
            </Box>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsSAAALEgHS3X78AAAB20lEQVQoz1WSeU/jMBDF+f7farV/UNhCl7ukuUqaNEfj+xo7jtehsID1NHq29NO80fhiXYSbctFt4Tel/7ufHg7z46Lppfavx/m1DWkXiiG8jeENhQqFBoeWho6Fiwivy/An9/tO9lhlNdmmVbpvW6SOo9wedNZC2emskUlFy1bWJ7MI+ci/w0W4St1AdAizdVNSYcoXr4x9KimiUmloBpLm5XDCUhvEZNnbhnzCq507URPez6+n7kBQNMa6h4IKbaMHB5eHS+td9EJB3kKN/8OpO5EFttYxRrjkZ/g+/4C994jjyU9nODtCHP4rdk+0lAIT0vcDxpgxJqS6zxlXMHsPAJTQWL2fIrxrYD9+iz0yAGOUUqCFsyYCBuxdzmJrhEZKKeecUCoEZ0K/NlCefswMYZ5Gom8SVDTsHHuT0diZCkIF06CNtXPwsfPLAfL+e2ysHOimp7/v6mQ/aiW5VJtsic0xoggpzkGpMLkIP1cQl/9jVSYSMsZCUjIZndIRZhKciV0XWWO8c1yZxzdI2k/4Op3KVjQDK+tx3+CyRlVLmpO4TXnVL+/xWnW06kg9sHoQj5XdHj/hdT5f7ewqgVi/lNjrbFrt4iNcp3ad2ZvM3uawKWz8ws9N+AdZIJpPECYvIAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Email Notifications",
          "title": "Email Notifications",
          "src": "/static/5bc82d01cb1f1a6be3c8f78165b22269/d9199/dl-email-1.png",
          "srcSet": ["/static/5bc82d01cb1f1a6be3c8f78165b22269/8ff5a/dl-email-1.png 240w", "/static/5bc82d01cb1f1a6be3c8f78165b22269/e85cb/dl-email-1.png 480w", "/static/5bc82d01cb1f1a6be3c8f78165b22269/d9199/dl-email-1.png 960w", "/static/5bc82d01cb1f1a6be3c8f78165b22269/07a9c/dl-email-1.png 1440w", "/static/5bc82d01cb1f1a6be3c8f78165b22269/0d390/dl-email-1.png 1472w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40.416666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsSAAALEgHS3X78AAABZklEQVQY0zWPiW7cMAxE/f//FSB3gzTBOvXKxzr2WrIs6qIOn9WmKDEgHkgOiMmeyu2JrI/n5fE8P5frS7W+NUvehfw7fNY2b01FQ81iw0LPfcuwGXTPkYJnas6KHrlE0qlTeW0HGKX7apXQzvrYUTFQ7uPCpW2pciEmIFU7CZmYAWYtc8dx7DM6dtr2PWHZ64FNICattZLSpGZcy0w6c3F7eO+DnxNz6bIL84mW6GhXh3Cbkt4MI1DKUlFKJQjQrqXJvCMi49JYe+z7KP3NvCyRjROfgHM+z7EakBAixYjOW2vXZVY2XAYdQhCQbkYASMzg5/O+rUrrNDLo1nWrrrYqK6PgFucW5FA2NoPZ9s1Y9D74OKcFA58VHfZM1d1U9yL1fjSnRoIJyqCQGlJkgwxs/fNZKgNSgbI+hEFg9lAsd3m8y8N/xfs/8fWMz4X9p5fCvhL7VuF7qX4V028CH2TKL5Jcw18wH7z1bDCS5AAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Email Notifications",
          "title": "Email Notifications",
          "src": "/static/a988739fd046fea23b188dd52a7be657/d9199/dl-email-2.png",
          "srcSet": ["/static/a988739fd046fea23b188dd52a7be657/8ff5a/dl-email-2.png 240w", "/static/a988739fd046fea23b188dd52a7be657/e85cb/dl-email-2.png 480w", "/static/a988739fd046fea23b188dd52a7be657/d9199/dl-email-2.png 960w", "/static/a988739fd046fea23b188dd52a7be657/07a9c/dl-email-2.png 1440w", "/static/a988739fd046fea23b188dd52a7be657/0d390/dl-email-2.png 1472w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>Landing Pages 💻</h5>
            <p>Although DisputeLab isn't public yet, we played around with a few introductory ideas when we would subsequently look for our first enterprise clients after beta testing with some of our existing clients. If conversion testing proved well, we would hopefully add testimonials from our beta clients to validate the product in the eyes of prospective clients.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.58333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACbklEQVQ4y6WU22sTQRTG88/6UPBFRBDFBx9ECoog2CJoK9KH1rZQQcWXIhQv0Gpr2lzs5lqbNDu7073O3iab+Tw7SZva+ubCj+/kzHe+ndldUkJ6BMAk2P+gdEZ2pEp51MbmxxWsLr/E+pvFK6ytLGJj7RXeEkX9L8/q8oJ6t7GE2DNUSYYt1Ot1HFQMHFQvUTlErd7A1rcKNj/v67roXfFVDVWp1JH4TVUaijY4t+G6IRzXJ4IJ4zoMI5RrHXz5Xkfgh+QLrvhoVnF+iixsjwMtZsLmHuEQ7rkyi2Ng2nQC2sVBFcc9Eybjk/Wpl3NXMWZNAzm3LuxqCiezxRiYaWotbsw5h+Nc8Ok6UEX/PNA55fB9AY+OdEYQCAwsB/tGD3u1Pn5Ue9ip9tE44rQW/uWlWeU4DgV2xoGe6yAUMT0voZ+ZT2FJnODn4QAPXu/i9vwebs3tYubRNp5v/EKWJtpTeDUiVp7nTQMD30UUp4iimEh0LagWdJM0SZEkCWlCQeO66IszL2kcpyoIfMizQBH6SFNJ5pQ0I0OEfJhjfI0INUZRbzQkkSQSWZZNZqQSIoQUk8A4CiDlkAxSwx0XO+VDLKx/wsqHr3i/VcbWbhPbtR7KLRtGz0d34CEIYwylLGZVHItpYJrGULSBESFlDk9I+vaaeDy/hNlnS7g7+wI37s/h+r2nmLnzROu1mw9RM7r6DPkIqtitDsyjDjhrwza7pF1YgzZOjg24/DdSYSIjEr8Px+7g1GzCZU2trG+AnbT0jM26ipttDIvA4s8hj1r0o3lOTsiwgdQ3NFnQoDfYoF6x3tJcmlHFjEq66g/HiANsJzbJfgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Landing Page",
          "title": "Landing Page",
          "src": "/static/3e0cee0fc20dc7b15e92a4c9c79df664/d9199/dl-landing.png",
          "srcSet": ["/static/3e0cee0fc20dc7b15e92a4c9c79df664/8ff5a/dl-landing.png 240w", "/static/3e0cee0fc20dc7b15e92a4c9c79df664/e85cb/dl-landing.png 480w", "/static/3e0cee0fc20dc7b15e92a4c9c79df664/d9199/dl-landing.png 960w", "/static/3e0cee0fc20dc7b15e92a4c9c79df664/07a9c/dl-landing.png 1440w", "/static/3e0cee0fc20dc7b15e92a4c9c79df664/1acf3/dl-landing.png 1596w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsSAAALEgHS3X78AAABtklEQVQoz5WSu24TQRSGt+QpqFJQwFPQ8AgRj0BFTU+VghoJKRU3KQUKFSQxgQQnJBgbsvaudze21473MveZM3sTlzBry7IjhBDSp1PNd+bMf8aq7GcQNCB4B+f7cH4Ag0MYNmF0DOEnGJ/CpAUXbZh+hciGqKdjVyd9nfoaDQrkW+C8xqokS4oryFXyBZlROIkt6G0TkVG5SlFXAUzlHIp55ZCz5QFt+nI8/UM2jqBU5TT7mVIZIVbXlE1TijkIXZpeM7nk6GIpM6Go/kEYyI2bsHlb+zveMDponpx+OTs6aX88/twPwhjzhIiFPKllLLTQhTfB8XSIu9vJozvywbXqyY1wFLZtr9WxO7bbdYMzx291uq4/4iqfyeO5nAmlP7x5FW3cUvct+fB69Xgt37qLMUcMEJMpMQhElQEzZcas5TScja1KjJB8sS5e3sN7z2lzSzq7TIg6WAFEaCo0WbAIrOTJaPlmno5pVtDvl7S8JPkvqoqrK1hFU1XxZDCX9axZZaKmXFKTnEHqvwP1zXFQf5Jag/9BlWYvPB5Y+benpPf23zg7xNkjboO4+6T/HnuH0mv8BogVgeASI3IsAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "404",
          "title": "404",
          "src": "/static/c48c9b878006c965fe88eb5647a855ec/d9199/dl-error.png",
          "srcSet": ["/static/c48c9b878006c965fe88eb5647a855ec/8ff5a/dl-error.png 240w", "/static/c48c9b878006c965fe88eb5647a855ec/e85cb/dl-error.png 480w", "/static/c48c9b878006c965fe88eb5647a855ec/d9199/dl-error.png 960w", "/static/c48c9b878006c965fe88eb5647a855ec/07a9c/dl-error.png 1440w", "/static/c48c9b878006c965fe88eb5647a855ec/0d390/dl-error.png 1472w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <h5>User Training & Documentation 📚</h5>
            <p>To help users understand how to use the basic and complex features of DisputeLab, we needed to build out a documentation site. We also needed a way for non-dev members to add/edit content on each documentation site.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "98.33333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsSAAALEgHS3X78AAAD/klEQVQ4yy2TyW8cRRTG+4/hwAWQQGGRDFw4cEfcuAASiQjEIXFQCEhEKEFsEiIEh0BC4kAAgQARhzgeLyS2Z+xkxhnvs3qm932pqt6qepnhtU3rU+lVdf+6Xr33FaffnzC2SzrfRGrHUZqW0qpU1/8qVW/Ord6YW526Uy8trk3OP7j5b31yvn7r7rok7LqmaEtbXnueU5cv291yQPPhcOgigkJ64nLr4YNLTx9befTN8stn6x9dbz12uHLg6PLjR8ojYyurXY/Q3NQltzXLKcuX3O5SHLMkYa6HkE/e+2H7ibcWnj9Rfm6s/NKZ6iuf1l58f3nk2NLI8fILJ5frHYvE1NQEtznDKeXvvF6Zsowx6iKM/GD0wtZDr80/Obr4zDvlZ8cqoJHjlaeOLj1y6O6BI4vVluXHyR5c4pSli05nIYpZnmceIpaHp1fV72/1r80KE6Xelen+xIxwdZq/ert3pcT/NCf0NewFDGCnMc3Ji+NuZ4El2SDPEcaKjVCIBywYDiEXxCI8HObDJMhCpwjyBFKzCLU03tmZ4qSFb5z2HZbmsDPxA91Gpm45HvaDUDFd28VhGFseUgwDeQSTwEa+/T/8Dyfd/RrgiKUJYxFUwkWOYzseCqIIu8j3gzimyA8tRMIwCsLIwQDHBbxdwF8BTNNhmuUxTUwU45ASP8J+BN3LBsM0L8YizuGbgedTO0gtXbC3JgE+Z27c8LRdrHc9raPwDV1sGVK7kNzRpLYutjWQ1NGlri6Ddg1VMPpr+/CX6vqkJgvIkl2dl8WeKvUtuauIPUPhI2RQ394XCxyQY2mGqZtSw978mxPnv7AaMxEbgMMw8TU3VG0iq4akmbJmYjhykoc0jVgGgsDx4720JXPjT06Y+9xuzlCWMhZjjA0Xi5q5Kyh9SRVVg5c1CHoiTDVYlw3bdIntQ595c/0PTpj9zGnOxHsOw4ToDsBWh1e6oiGbRDBDwYxUJzQxNTGzCLNQaPu0gNd+B/gTa+d2RBNoNMYAI8MNKrWdwx9+e/DU+VffPff6yfNvnBo/9MHFt09fGj09Xt/ZhYthKH2j/hvHzwA8vW+SAraRh0PD9pY2mpXt7kqjB7rX7FfbfK0j1Nq8bLpukJhq33jwK8eXPoadaZJlWRoEIaQNPosiutnQXRRBFQdFKYsnywdJmroktAkz1Z5R+xngs9b2FJw5SSiYyfAI2CumNEmzMAwJIXmaUgqvYphCRV0cFGdWd/Xada4/fcbamWIZuCejDBwWEWhIVmyXJBkYFoI8H8AK/I74oekFbpRD2nr1xyJttfaLxa/b/brF18V2TWpVpdY9GcbmPbGxosBKuwZTpbPa3az0du7rYlNtlfXqtf8Aj2QGv4nr0gUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Training",
          "title": "Training",
          "src": "/static/1c517591daf46b1d4b1b2c2853980b09/d9199/drm-docs.png",
          "srcSet": ["/static/1c517591daf46b1d4b1b2c2853980b09/8ff5a/drm-docs.png 240w", "/static/1c517591daf46b1d4b1b2c2853980b09/e85cb/drm-docs.png 480w", "/static/1c517591daf46b1d4b1b2c2853980b09/d9199/drm-docs.png 960w", "/static/1c517591daf46b1d4b1b2c2853980b09/07a9c/drm-docs.png 1440w", "/static/1c517591daf46b1d4b1b2c2853980b09/29007/drm-docs.png 1600w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
            <p>Along with the tutorial documentation, enterprise clients would probably need to utilize a status page to keep track of the handful of testing versions of the application and API. Rather than use the full weight of DisputeLab's UI, I thought to create a lightweight version of the UI using the design tokens we use within the application.</p>
            <p>Also, since we were moving the front-end portions of DisputeLab towards a React build, we thought Gatsby.js might be a nice way to migrate and test how a few of the basic components might function within a modern JS framework - since this app would be moving to React.</p>
        </Col>
        <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </CSSGrid>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsSAAALEgHS3X78AAABXklEQVQoz3WR2U6DQBSG++ZetMbWGL3QFzJdoqYmak0aSwyo7LMwG0OhA3iKjbGAw3cxcObjnB8GKJExFVwUC3t5Nr+4uru5vL8eTSfD6Xg4m5wuzkezZj8dw8OT29HcXsLhmHIQB4SnQCIyJwofNs+P1uuTvV551h5302Adbj3rxdu4BMPhH+sgU6H9mNi26zi+68Vam15SvWPyYP6RuQ4YjlCMMC6Koq7rqrNMuRNbzmTaI/sJjuHCVOusquqyrFoYY1SumNS4V0YEGmMhRN20blGWRuaSKd3pDJkx+XC9Ly+gXAqVcalbwMCYc8JVW05k5gZotbbe3h3M5G/5GNWUVE/mkBGS0GwfGCbsWTtj0jyFsTuZReZR5AU+Qqgo8roJ3QI+mczFXmbdzBSHcQTds+02hxd02OY51yLp/ioAMxVihqggDLL1IlEiYNMjQ+HTjyLMYIp/PhhwVPoGt/fTuLN046wAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Operational Status",
          "title": "Operational Status",
          "src": "/static/6004519ca3247be9af3063e51c54eea1/d9199/drm-status.png",
          "srcSet": ["/static/6004519ca3247be9af3063e51c54eea1/8ff5a/drm-status.png 240w", "/static/6004519ca3247be9af3063e51c54eea1/e85cb/drm-status.png 480w", "/static/6004519ca3247be9af3063e51c54eea1/d9199/drm-status.png 960w", "/static/6004519ca3247be9af3063e51c54eea1/21b4d/drm-status.png 1280w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      